@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes progress {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

.animate-progress {
    animation: progress 3s ease-in-out;
    animation-fill-mode: forwards;
}